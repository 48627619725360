import React, { useState } from 'react';
import PropTypes from 'prop-types';

import randomString from '../../utils/randomString';
import truncateString from '../../utils/truncateString';

import IconCircleCross from '../../images/IconCircleCross';

import StyledInputFile, {
  HiddenInputFile,
  InputFileLabel,
  StyledIconFileUpload,
  StyledIconFileUploaded,
  RemoveFileButton,
  InputFileWrapper,
} from './FileInput.styles';

const getFileExtension = (fullName) => {
  const idx = fullName.lastIndexOf('.');
  return idx < 1 ? '' : fullName.substr(idx);
};

const getFileName = (fullName) => {
  const idx = fullName.lastIndexOf('.');
  return idx < 1 ? fullName : fullName.substr(0, idx);
};

const FileInput = ({ id, name, disabled, onChange, ...rest }) => {
  const [file, setFile] = useState(null);
  const [label, setLabel] = useState('');

  const inputFileId = id || randomString();

  const initialLabel = 'Télécharger';

  const initLabel = (fullName) => {
    const fileName = getFileName(fullName);
    const fileExtension = getFileExtension(fullName);
    const output =
      fileName.length <= 8 ? fileName : truncateString(fullName, 8, '.');

    setLabel(output + fileExtension);
  };

  const handleFileChange = (e) => {
    if (!e.target.files) return;

    setFile(e.target.files[0]);
    initLabel(e.target.files[0].name);

    onChange(e.target.files[0]);
  };

  const handleRemoveFileClick = (e) => {
    e.preventDefault();

    setFile(null);
    setLabel(initialLabel);

    onChange(null);
  };

  return (
    <InputFileWrapper>
      {file && (
        <RemoveFileButton onClick={handleRemoveFileClick}>
          <IconCircleCross aria-label='Supprimer le fichier téléchargé' />
        </RemoveFileButton>
      )}

      <InputFileLabel
        htmlFor={inputFileId}
        disabled={disabled}
        primary={!!file}
        {...rest}
      >
        <HiddenInputFile
          id={inputFileId}
          name={name}
          disabled={disabled}
          onChange={handleFileChange}
        />

        <StyledInputFile>
          {file ? <StyledIconFileUploaded /> : <StyledIconFileUpload />}

          <span>{label || initialLabel}</span>
        </StyledInputFile>
      </InputFileLabel>
    </InputFileWrapper>
  );
};

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FileInput.defaultProps = {
  id: undefined,
  disabled: false,
  onChange: () => {},
};

export default FileInput;
