// vendors
import React from 'react';
import PropTypes from 'prop-types';
import randomString from '../../../utils/randomString';
import { Label, Checkbox, Text } from './Tooth.styles';

const Tooth = ({ name, value, checked, disabled, onChange, onBlur }) => {
  const id = randomString();

  return (
    <Label htmlFor={id}>
      <Checkbox
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />

      <Text>{value}</Text>
    </Label>
  );
};

Tooth.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
Tooth.defaultProps = {
  checked: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
};

export default Tooth;
