import React from 'react';
import { useFormikContext } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';

import RadioButton from '../../RadioButton';
import { Grid } from '../Form.styles';

const query = graphql`
  query {
    locations: allSanityLocation(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          name
        }
      }
    }
  }
`;

const CliniquesFormSection = () => {
  const { handleChange, handleBlur, values } = useFormikContext();

  const {
    locations: { edges: cliniques = [] },
  } = useStaticQuery(query);

  return (
    <Grid>
      {cliniques.map(({ node }) => (
        <RadioButton
          key={node.name}
          name='clinic'
          value={node.name}
          checked={values.clinic === node.name}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {node.name}
        </RadioButton>
      ))}
    </Grid>
  );
};

export default CliniquesFormSection;
