import React from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import {
  Grid,
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';

import RadioButton from '../../../../components/RadioButton';
import TextInputField from '../../../../components/TextInputField';

export const title = "Type d'intervention et informations supplémentaires";

export const initialValues = {
  intervention: '',
  dentalImplantType: '',
};

export const validationSchema = Yup.object().shape({
  // dentalImplantType: Yup.string().when('intervention', {
  //   is: (val) => val === 'Implants dentaires',
  //   then: Yup.string().required("Insérez un type d'implants dentaires"),
  // }),
});

const interventions = [
  'Extraction',
  'Implants dentaires',
  'Sédation',
  'Pathologie buccale',
  'Chirurgie orthognatique',
  'Exposition / Ligature',
  'Dysfonction ATM',
  'Chirurgie pré-prothétique',
  'Apectomie',
  'Malformation oro-faciale',
  'Esthétique',
  'Autres',
];

const InterventionStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormikContext();

  return (
    <Stack>
      <Fieldset>
        <Legend>Spécifier le type d&apos;intervention</Legend>

        <FieldsetContent>
          <Grid>
            {interventions.map((intervention) => (
              <RadioButton
                key={intervention}
                name='intervention'
                value={intervention}
                checked={values.intervention === intervention}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {intervention}
              </RadioButton>
            ))}
          </Grid>
        </FieldsetContent>
      </Fieldset>

      {values.intervention === 'Implants dentaires' && (
        <Fieldset>
          <Legend>Informations supplémentaires</Legend>

          <FieldsetContent>
            <TextInputField
              label="Type d'implants dentaires"
              name='dentalImplantType'
              value={values.dentalImplantType}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.dentalImplantType && touched.dentalImplantType}
              helperText={
                touched.dentalImplantType ? errors.dentalImplantType : ''
              }
              css={`
                grid-row: 4;
              `}
            />
          </FieldsetContent>
        </Fieldset>
      )}
    </Stack>
  );
};

export default InterventionStep;
