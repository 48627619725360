export default (title, firstName, lastName) => {
  let string = '';

  if (title) {
    string += `${title} `;
  }

  string += `${firstName} ${lastName}`;

  return string;
};
