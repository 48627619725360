import React from 'react';

const IconFileUpload = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.7 67.96' {...props}>
    <g>
      <path
        d='M51.41,16.68,34.77.29a1,1,0,0,0-.7-.29H1A1,1,0,0,0,0,1V67a1,1,0,0,0,1,1H50.7a1,1,0,0,0,1-1V17.39A1,1,0,0,0,51.41,16.68ZM34.89,3.21,48.54,16.66H34.89ZM2,66V2H32.89V18.66H49.7V66Z'
        fill='currentColor'
      />
      <path
        d='M36.45,42,35,40.69l-8.23,9V28.15h-2V49.47l-8.11-8.79L15.17,42,25.73,53.48H15.15v2H36.66v-2H25.94Z'
        fill='currentColor'
      />
    </g>
  </svg>
);

export default IconFileUpload;
