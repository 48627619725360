import React from 'react';

import colors from '../styles/colors';

const IconCircleCross = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.92 28.92' {...props}>
    <g>
      <path
        d='M14.46,28.92A14.46,14.46,0,1,0,0,14.46,14.47,14.47,0,0,0,14.46,28.92'
        fill='currentColor'
      />
      <polygon
        points='19.96 10.48 18.6 9.12 14.46 13.26 10.32 9.12 8.96 10.48 13.1 14.62 8.96 18.76 10.32 20.11 14.46 15.97 18.6 20.11 19.95 18.76 15.81 14.62 19.96 10.48'
        fill={colors.mineShaft}
      />
    </g>
  </svg>
);

export default IconCircleCross;
