// vendors
import styled from 'styled-components';
import { hideVisually } from 'polished';
import colors from '../../../styles/colors';
import { easing, speed } from '../../../styles/animation';
import breakpointsRange from '../../../utils/breakpointsRange';
import breakpoints from '../../../styles/breakpoints';

export const Label = styled.label`
  display: inline-block;
  margin: 0;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually};
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpointsRange(
    [
      {
        prop: 'width',
        sizes: [30, 46],
        bases: [13, 20],
        unit: 'em',
      },
      {
        prop: 'height',
        sizes: [30, 46],
        bases: [13, 20],
        unit: 'em',
      },
      {
        prop: 'fontSize',
        sizes: [13, 20],
      },
    ],
    breakpoints.fonts
  )};

  color: ${colors.brownGrey};

  border: 1.5px solid ${colors.brownGrey};

  transition-timing-function: ${easing.inOutBack};
  transition-duration: ${speed.fast};
  transition-property: opacity, transform;

  will-change: color, background-color;

  ${Checkbox}:focus ~ &,
  :hover {
    color: ${colors.black};

    background-color: ${colors.brownGrey};
  }

  ${Checkbox}:checked ~ & {
    color: ${colors.black};

    background-color: ${colors.azure};
    border-color: ${colors.azure};
  }
`;
