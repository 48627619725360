// Vendors
import React from 'react';
import colors from '../../../../styles/colors';

const IntroStep = () => {
  return (
    <p style={{ color: colors.brownGrey }}>
      Cher partenaire, <br />
      Merci de nous faire confiance et de nous référer votre patient. Nous vous
      invitons à remplir le formulaire ci-après afin de connaître les besoins de
      votre patient. Toutes les informations et données recueillies dans ce dit
      formulaire seront sécurisées et utilisées avec confidentialité. Merci de
      faire confiance à Maxillo Québec.
    </p>
  );
};

export default IntroStep;
