import React from 'react';
import * as yup from 'yup';

import { useFormikContext } from 'formik';
import { css } from 'styled-components';
import {
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';

import Odontogram from '../../../../components/Odontogram/Odontogram';
import Checkbox from '../../../../components/Checkbox';
import FormHelperText from '../../../../components/FormHelperText';

export const title = 'Odontogramme du patient';

export const initialValues = {
  odontogram: [],
  noOdontogram: false,
};

export const validationSchema = yup.object({
  odontogram: yup.array().when('noOdontogram', {
    is: false,
    then: yup.array().min(1, "Il manque une selection sur l'odontogramme"),
    otherwise: yup.array(),
  }),
  noOdontogram: yup.boolean(),
});

const OdontogramStep = () => {
  const { setFieldValue, values, errors } = useFormikContext();

  const handleOdontogramChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValues = [...values[name]];

    const index = newValues.indexOf(Number(value));

    if (index > -1) {
      newValues = [...newValues.filter((el) => Number(el) !== Number(value))];
    } else {
      newValues.push(Number(value));
    }

    setFieldValue(name, newValues);
  };

  return (
    <Stack>
      <Fieldset>
        <Legend>Compléter l’odontogramme du patient</Legend>

        <FieldsetContent>
          {!values.noOdontogram && (
            <div
              css={css`
                margin-right: calc(50% - 50vw);
                margin-left: calc(50% - 50vw);
              `}
            >
              <Odontogram
                onChange={handleOdontogramChange}
                name='odontogram'
                values={values.odontogram}
              />

              <Odontogram
                onChange={handleOdontogramChange}
                name='odontogram'
                values={values.odontogram}
                kid
              />
            </div>
          )}

          <div
            css={css`
              display: flex;
              flex-flow: column;
              align-items: center;
              margin: auto;
            `}
          >
            <Checkbox
              name='noOdontogram'
              onChange={() => {
                setFieldValue('noOdontogram', !values.noOdontogram);
              }}
              checked={values.noOdontogram}
            >
              Ne s’applique pas à ce patient ou au type d’intervention requise.
            </Checkbox>

            {errors.odontogram && (
              <FormHelperText error>{errors.odontogram}</FormHelperText>
            )}
          </div>
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default OdontogramStep;
