import React from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { rem } from 'polished';
import InputMask from 'react-input-mask';

import '../../../../components/Form/Validations/yupPhoneValidation';

import theme from '../../../../styles/theme';
import { greaterThan } from '../../../../utils/mediaQuery';
import beforeMaskedStateChange from '../../../../utils/beforeMaskedStateChange';

import TextInputField from '../../../../components/TextInputField';
import TextAreaField from '../../../../components/TextAreaField';
import SelectField from '../../../../components/SelectField';
import SelectOption from '../../../../components/Select/SelectOption';

import {
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';

export const title = 'Fiche du patient';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Insérez le prénom du patient')
    .min(2, 'Le prénom inséré est trop court')
    .max(50, 'Le prénom inséré est trop long'),
  lastName: Yup.string()
    .required('Insérez le nom du patient')
    .min(2, 'Le nom inséré est trop court')
    .max(50, 'Le nom inséré est trop long'),
  phone: Yup.string()
    .required('Insérez un numéro de téléphone')
    .phone('CA', 'Insérez un numéro de téléphone valide'),
  cell: Yup.string(),
  email: Yup.string().email('Insérez une adresse courriel valide'),
  dateBirth: Yup.date()
    .required('Insérez la date de naissance du patient')
    .typeError('Insérez une date valide'),
  confirmationType: Yup.string().required(
    'Sélectionnez un type de confirmation'
  ),
});

const confirmationTypeOptions = ['Courriel', 'Téléphone', 'Texto'];

export const initialValues = {
  lastName: '',
  firstName: '',
  phone: '',
  cell: '',
  email: '',
  dateBirth: '',
  conditions: '',
  confirmationType: '',
};

const fillSelectPlaceholder = (text) => [
  <SelectOption key={text} disabled hidden value=''>
    {text}
  </SelectOption>,
];

const fillSelectOptions = (opts) => {
  return opts.map((option, index) => {
    return (
      <SelectOption key={String(index + 1)} value={option}>
        {option}
      </SelectOption>
    );
  });
};

const PatientFileStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormikContext();

  return (
    <Stack>
      <Fieldset>
        <Legend>Compléter la fiche du patient</Legend>

        <FieldsetContent>
          <div
            css={`
              display: grid;
              grid-gap: ${rem(32)};
              grid-template-columns: 1fr;

              ${greaterThan(theme.breakpoints.fonts[0])} {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(5, 1fr);
              }
            `}
          >
            <TextInputField
              label='Nom*'
              name='lastName'
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.lastName && touched.lastName}
              helperText={touched.lastName ? errors.lastName : ''}
            />

            <TextInputField
              label='Prénom*'
              name='firstName'
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.firstName && touched.firstName}
              helperText={touched.firstName ? errors.firstName : ''}
            />

            <InputMask
              mask='(999) 999-9999'
              beforeMaskedStateChange={beforeMaskedStateChange}
              type='phone'
              name='phone'
              value={values.phone}
              error={errors.phone && touched.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={false}
            >
              <TextInputField
                label='Téléphone*'
                helperText={touched.phone ? errors.phone : ''}
              />
            </InputMask>

            <InputMask
              mask='(999) 999-9999'
              beforeMaskedStateChange={beforeMaskedStateChange}
              type='phone'
              name='cell'
              value={values.cell}
              error={errors.cell && touched.cell}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={false}
            >
              <TextInputField
                label='Cellulaire'
                helperText={touched.cell ? errors.cell : ''}
              />
            </InputMask>

            <TextInputField
              label='Courriel'
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              helperText={touched.email ? errors.email : ''}
            />

            <InputMask
              mask='9999/99/99'
              beforeMaskedStateChange={beforeMaskedStateChange}
              name='dateBirth'
              value={values.dateBirth}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.dateBirth && touched.dateBirth}
              disabled={false}
            >
              <TextInputField
                label='Date de naissance* AAAA/MM/JJ'
                helperText={touched.dateBirth ? errors.dateBirth : ''}
              />
            </InputMask>

            <SelectField
              label='Type de confirmation*'
              name='confirmationType'
              value={values.confirmationType}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.confirmationType && touched.confirmationType}
              helperText={
                touched.confirmationType ? errors.confirmationType : ''
              }
            >
              {fillSelectPlaceholder('Sélectionnez une option').concat(
                fillSelectOptions(confirmationTypeOptions)
              )}
            </SelectField>

            <TextAreaField
              label='Présente les conditions suivantes:'
              name='conditions'
              value={values.conditions}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.conditions && touched.conditions}
              helperText={touched.conditions ? errors.conditions : ''}
              rows='5'
              css={`
                grid-row: span 2;
              `}
            />

            <p
              css={`
                margin: 0;
                /* grid-column: 1 / span 2; */
                /* grid-row: 6; */
              `}
            >
              * Champs obligatoires
            </p>
          </div>
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default PatientFileStep;
