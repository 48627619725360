import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import { PrivacyPolicyCheckbox } from '../../../../components/PrivacyPolicy';

export const title = 'Certification des informations';

export const initialValues = {
  hasConfirmed: false,
};

export const validationSchema = yup.object({
  hasConfirmed: yup
    .boolean()
    .required(
      'Veuillez cocher afin de confirmer que ces informations sont bien les vôtres.'
    )
    .oneOf(
      [true],
      'Veuillez cocher afin de confirmer que ces informations sont bien les vôtres.'
    ),
});
const ConfirmInformationStep = () => {
  const { setFieldValue, values, errors } = useFormikContext();

  return (
    <PrivacyPolicyCheckbox
      name='hasConfirmed'
      onChange={() => {
        setFieldValue('hasConfirmed', !values.hasConfirmed);
      }}
      checked={values.hasConfirmed}
      error={errors.hasConfirmed}
    />
  );
};

export default ConfirmInformationStep;
