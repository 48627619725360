import React from 'react';

import {
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';

import CliniquesFormSection from '../../../../components/Form/CliniquesFormSection';
import SpecialistsFormSection from '../../../../components/Form/SpecialistsFormSection';

export const title = 'Choisir votre clinique et spécialiste';

export const initialValues = {
  clinic: '',
  specialists: [],
};

const CliniqueSpecialistsStep = () => {
  return (
    <Stack>
      <Fieldset>
        <Legend>Choisir votre clinique</Legend>

        <FieldsetContent>
          <CliniquesFormSection />
        </FieldsetContent>
      </Fieldset>

      <Fieldset>
        <Legend>Choisir votre spécialiste</Legend>

        <FieldsetContent>
          <SpecialistsFormSection />
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default CliniqueSpecialistsStep;
