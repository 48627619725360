import styled, { css } from 'styled-components';
import { em, hideVisually } from 'polished';

import responsiveStyle from '../../utils/responsiveStyle';
import { greaterThan } from '../../utils/mediaQuery';
import { speed, easing } from '../../styles/animation';
import theme from '../../styles/theme';

import IconFileUpload from '../../images/IconFileUpload';
import IconFileUploaded from '../../images/IconFileUploaded';

export const HiddenInputFile = styled.input.attrs({ type: 'file' })`
  ${hideVisually};
`;

const marginTop = [66, 82];

export const InputFileWrapper = styled.div`
  position: relative;

  display: inline-grid;

  width: 120px;

  ${greaterThan(theme.breakpoints.fonts[0])} {
    width: 100%;
    min-width: 120px;
    max-width: 152px;
  }
  margin-top: ${marginTop[0]}px;

  ${greaterThan(theme.breakpoints.fonts[0])} {
    margin-top: ${marginTop[1]}px;
  }
`;

export const InputFileLabel = styled.label`
  position: relative;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: inherit;

  color: ${(props) =>
    props.primary ? theme.colors.primary : theme.colors.brownGrey};
  font-weight: ${theme.fontWeights.body};

  ${responsiveStyle('fontSize', [12, 15, null], theme.breakpoints.fonts)};

  line-height: ${theme.lineHeights.body[0]};
`;

export const iconStyle = css`
  position: absolute;
  top: -${marginTop[0]}px;

  width: 42px;

  ${greaterThan(theme.breakpoints.fonts[0])} {
    top: -${marginTop[1]}px;

    width: 52px;
  }

  cursor: pointer;
`;

export const StyledIconFileUpload = styled(IconFileUpload)`
  ${iconStyle};
`;
export const StyledIconFileUploaded = styled(IconFileUploaded)`
  ${iconStyle};

  color: ${theme.colors.primary};
`;

export const RemoveFileButton = styled.button`
  position: absolute;
  top: -86px;
  right: 16px;

  ${greaterThan(theme.breakpoints.fonts[0])} {
    top: -109px;
    right: 16px;

    width: 30px;
  }

  display: grid;

  width: 25px;
  padding: 0;

  color: ${theme.colors.primary};

  background: none;
  border: 0;

  outline: 0;

  cursor: pointer;

  ::before {
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: -1;

    width: calc(100% + 8px);
    height: calc(100% + 8px);

    background-color: transparent;
    border: 2px solid ${theme.colors.wildSand800};
    border-radius: 50%;

    transform: scale(0);
    opacity: 0;

    transition-timing-function: ${easing.inOutBack};
    transition-duration: ${speed.fast};
    transition-property: opacity, transform;

    content: '';

    will-change: opacity, transform;
  }

  :focus::before {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledInputFile = styled.div`
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: inherit;

  > span {
    width: inherit;
    padding: ${em(10)} ${em(20)};

    text-align: center;

    background-color: ${theme.colors.mineShaft400};
    border-radius: 50px;

    cursor: pointer;
  }

  ::before {
    position: absolute;
    z-index: -1;

    width: calc(100% + 8px);
    height: calc(100% + 8px);

    background-color: transparent;
    border: 2px solid ${theme.colors.wildSand800};
    border-radius: 50px;

    transform: scale(0);
    opacity: 0;

    transition-timing-function: ${easing.inOutBack};
    transition-duration: ${speed.fast};
    transition-property: opacity, transform;

    content: '';

    will-change: opacity, transform;
  }

  ${HiddenInputFile}:focus ~ &::before {
    transform: scale(1);
    opacity: 1;
  }
`;

export default StyledInputFile;
