// vendors
import React from 'react';
import PropTypes from 'prop-types';
import Containter from './Odontogram.styles';
import MouthSide from './MouthSide';

const Odontogram = ({ kid, onChange, onBlur, values, name }) => {
  const numTeethByQuarter = kid ? 5 : 8;

  const handleChange = (e) => {
    onChange(e);
  };

  const handleBlur = (e) => {
    onBlur(e);
  };

  return (
    <Containter>
      <MouthSide
        numberTeethByQuarter={numTeethByQuarter}
        right
        kid={kid}
        onChange={handleChange}
        onBlur={handleBlur}
        values={values}
        name={name}
      />

      <MouthSide
        numberTeethByQuarter={numTeethByQuarter}
        left
        kid={kid}
        onChange={handleChange}
        onBlur={handleBlur}
        values={values}
        name={name}
      />
    </Containter>
  );
};

Odontogram.propTypes = {
  kid: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
};

Odontogram.defaultProps = {
  kid: false,
  onChange: () => {},
  onBlur: () => {},
  values: [],
  name: '',
};

export default Odontogram;
