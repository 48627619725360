// vendors
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import colors from '../../../styles/colors';
import { greaterThan, lessThan } from '../../../utils/mediaQuery';
import breakpoints from '../../../styles/breakpoints';
import breakpointsRange from '../../../utils/breakpointsRange';

export const Top = styled.div`
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;

  ${breakpointsRange(
    [
      {
        prop: 'paddingBottom',
        sizes: [13, 21],
      },
    ],
    breakpoints.fonts
  )};

  ${greaterThan(768)} {
    grid-row: 1 / span 1;

    border-bottom: 1px solid ${colors.azure};
  }
`;

export const Bottom = styled.div`
  grid-row: 3 / span 1;
  grid-column: 2 / span 1;

  ${breakpointsRange(
    [
      {
        prop: 'paddingTop',
        sizes: [13, 21],
      },
    ],
    breakpoints.fonts
  )};

  ${greaterThan(768)} {
    grid-row: 2 / span 1;
  }
`;

export const Title = styled.p`
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;

  margin: 0;

  margin-bottom: 18px;

  color: ${colors.azure};

  font-weight: 600;
  text-align: center;

  ${breakpointsRange(
    [
      {
        prop: 'fontSize',
        sizes: [30, 56],
      },
      {
        prop: 'width',
        sizes: [30, 46],
      },
    ],
    breakpoints.fonts
  )};

  ${greaterThan(768)} {
    grid-row: 1 / span 2;

    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr 1fr;
  grid-template-columns: 1fr auto 1fr;

  margin: ${rem(28)} 0;

  ${greaterThan(768)} {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto 1fr;
  }

  ${Title} {
    ${greaterThan(768)} {
      grid-column: ${({ left }) => (left ? 2 : 1)} / span 1;
      align-self: center;
    }
  }

  /* stylelint-disable-next-line rule-empty-line-before */
  ${Top},
  ${Bottom} {
    ${greaterThan(768)} {
      ${({ left }) =>
        left &&
        css`
          grid-column: 1 / span 1;

          ${breakpointsRange(
            [
              {
                prop: 'paddingLeft',
                sizes: [13, 21],
              },
            ],
            breakpoints.fonts
          )};
        `}

      ${({ right }) =>
        right &&
        css`
          grid-column: 2 / span 1;
          border-right: 1px solid ${colors.azure};

          ${breakpointsRange(
            [
              {
                prop: 'paddingRight',
                sizes: [13, 21],
              },
            ],
            breakpoints.fonts
          )};
        `}
    }
  }

  ::before {
    ${lessThan(768)} {
      grid-row: 2 / span 1;

      border-bottom: 1px solid ${colors.azure};

      content: '';

      ${({ left }) =>
        left &&
        css`
          grid-column: 1 / span 2;
        `};

      ${({ right }) =>
        right &&
        css`
          grid-column: 2 / span 2;
        `};
    }
  }

  ::after {
    ${lessThan(768)} {
      grid-row: 2 / span 2;
      width: 1px;

      background-color: ${colors.azure};

      content: '';

      ${({ left }) =>
        left &&
        css`
          justify-self: end;
          grid-column: 1 / span 1;
          margin-right: 13px;
        `};

      ${({ right }) =>
        right &&
        css`
          justify-self: start;
          grid-column: 3 / span 1;
          margin-left: 13px;
        `};
    }
  }
`;
