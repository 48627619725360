import React from 'react';
import { useFormikContext } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';

import buildSpecialistName from '../../../utils/buildSpecialistName';

import { Grid } from '../Form.styles';
import Checkbox from '../../Checkbox';

const query = graphql`
  query {
    teamMembers: allSanityTeamMember(
      sort: { fields: contactFormSort, order: ASC }
      filter: { contactFormVisible: { eq: true } }
    ) {
      edges {
        node {
          title
          firstname
          lastname
          location {
            name
          }
        }
      }
    }
  }
`;

const SpecialistsFormSection = () => {
  const { handleBlur, values, setFieldValue } = useFormikContext();

  const {
    teamMembers: { edges: specialistsFromQuery = [] },
  } = useStaticQuery(query);

  const specialists = specialistsFromQuery
    .filter(({ node }) => {
      if (values.clinic === '') return true;

      return node.location.map(({ name }) => name).includes(values.clinic);
    })
    .map(({ node }) => {
      return buildSpecialistName(node.title, node.firstname, node.lastname);
    });

  const handleChange = (e) => {
    const {
      target: { value, name },
    } = e;
    let newValues = [...values[name]];

    const index = newValues.indexOf(value);

    if (index > -1) {
      newValues = [...newValues.filter((el) => el !== value)];
    } else {
      newValues.push(value);
    }

    setFieldValue(name, newValues);
  };

  return (
    <Grid>
      {specialists.map((specialist) => (
        <Checkbox
          key={specialist}
          name='specialists'
          value={specialist}
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.specialists.includes(specialist)}
        >
          {specialist}
        </Checkbox>
      ))}

      <Checkbox
        name='specialists'
        value='Premier disponible'
        onChange={handleChange}
        onBlur={handleBlur}
        checked={values.specialists.includes('Premier disponible')}
      >
        Premier disponible
      </Checkbox>
    </Grid>
  );
};

export default SpecialistsFormSection;
