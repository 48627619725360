import React from 'react';

const IconFileUploaded = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.7 67.96' {...props}>
    <g>
      <path
        d='M51.41,16.68,34.77.29a1,1,0,0,0-.7-.29H1A1,1,0,0,0,0,1V67a1,1,0,0,0,1,1H50.7a1,1,0,0,0,1-1V17.39A1,1,0,0,0,51.41,16.68ZM34.89,3.21,48.54,16.66H34.89ZM2,66V2H32.89V18.66H49.7V66Z'
        fill='currentColor'
      />
      <path
        d='M37.15,29.71c-1.7-1.39-4-.93-6.54-.43a24.29,24.29,0,0,1-4.74.6,24.29,24.29,0,0,1-4.74-.6c-2.49-.5-4.84-1-6.54.43-1.48,1.22-2.15,3.58-2.15,7.65,0,5.78,1.69,18.48,6.7,18.58h.05c2.11,0,3.32-2.43,4.5-4.79.56-1.13,1.61-3.22,2.18-3.22s1.63,2.09,2.2,3.21c1.17,2.33,2.39,4.74,4.48,4.8h.08a3.29,3.29,0,0,0,2.31-1c2.83-2.75,4.36-11.09,4.36-17.54C39.3,33.29,38.63,30.93,37.15,29.71ZM33.54,53.47c-.49.48-.83.48-.94.47-.89,0-2-2.15-2.75-3.7-1.12-2.22-2.17-4.31-4-4.31S23,48,21.9,50.26c-.78,1.55-1.84,3.68-2.71,3.68h0c-2.35,0-4.74-8.41-4.74-16.58,0-3.27.48-5.33,1.42-6.1s2.7-.45,4.89,0a26.32,26.32,0,0,0,5.12.64A26.32,26.32,0,0,0,31,31.24c2.19-.43,3.92-.78,4.89,0s1.42,2.83,1.42,6.1C37.3,43.38,35.79,51.28,33.54,53.47Z'
        fill='currentColor'
      />
      <rect x='9.63' y='9.38' width='13.48' height='2' fill='currentColor' />
      <rect x='9.63' y='16.07' width='9.13' height='2' fill='currentColor' />
    </g>
  </svg>
);

export default IconFileUploaded;
