// vendors
import React from 'react';
import PropTypes from 'prop-types';
import Tooth from '../Tooth';
import Containter from './MouthQuarter.styles';

const MouthQuarter = ({
  numberTeeth,
  top,
  bottom,
  left,
  right,
  kid,
  onChange,
  onBlur,
  values,
  name,
}) => {
  let startingNumber = 0;

  if (top && right) startingNumber = 10;
  if (top && left) startingNumber = 20;
  if (bottom && right) startingNumber = 40;
  if (bottom && left) startingNumber = 30;
  if (kid) startingNumber += 40;

  return (
    <Containter>
      {Array(numberTeeth)
        .fill()
        .map((_, index) => {
          const value = right
            ? numberTeeth - index + startingNumber
            : startingNumber + index + 1;
          return (
            <Tooth
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              checked={values.includes(value)}
            />
          );
        })}
    </Containter>
  );
};

MouthQuarter.propTypes = {
  numberTeeth: PropTypes.number,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  kid: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
};

MouthQuarter.defaultProps = {
  numberTeeth: 8,
  top: false,
  bottom: false,
  left: false,
  right: false,
  kid: false,
  onChange: () => {},
  onBlur: () => {},
  values: [],
  name: '',
};

export default MouthQuarter;
