// vendors
import styled from 'styled-components';
import { rem } from 'polished';
import { greaterThan } from '../../utils/mediaQuery';

export default styled.div`
  margin: ${rem(48)} 0;

  ${greaterThan(768)} {
    display: flex;
    justify-content: center;
  }
`;
