import React from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { rem } from 'polished';
import InputMask from 'react-input-mask';

import '../../../../components/Form/Validations/yupPhoneValidation';

import theme from '../../../../styles/theme';
import { lessThan } from '../../../../utils/mediaQuery';
import beforeMaskedStateChange from '../../../../utils/beforeMaskedStateChange';

import TextInputField from '../../../../components/TextInputField';
import TextAreaField from '../../../../components/TextAreaField';
import SelectField from '../../../../components/SelectField';
import SelectOption from '../../../../components/Select/SelectOption';
import FileInput from '../../../../components/FileInput';
import FormHelperText from '../../../../components/FormHelperText';

import {
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';

export const title = 'Compléter les renseignements cliniques';

export const initialValues = {
  details: '',
  referentName: '',
  referentPhone: '',
  referentEmail: '',
  radiography: '',
  documents: [],
  dateRadiography: '',
};

export const validationSchema = Yup.object().shape({
  referentName: Yup.string()
    .required('Insérez le nom du référent')
    .min(2, 'Le prénom inséré est trop court')
    .max(50, 'Le prénom inséré est trop long'),
  referentPhone: Yup.string()
    .required('Insérez un numéro de téléphone')
    .phone('CA', 'Insérez un numéro de téléphone valide'),
  referentEmail: Yup.string()
    .required('Insérez une adresse courriel')
    .email('Insérez une adresse courriel valide'),
  dateRadiography: Yup.date().typeError('Insérez une date valide'),
});

const radiographyOptions = [
  'MaxilloQuébec devra prendre une radiographie',
  'Radiographie remise au patient',
];

const fillSelectPlaceholder = (text) => [
  <SelectOption key={text} disabled hidden value=''>
    {text}
  </SelectOption>,
];

const fillSelectOptions = (opts) => {
  return opts.map((option, index) => {
    return (
      <SelectOption key={String(index + 1)} value={option}>
        {option}
      </SelectOption>
    );
  });
};

const ClinicalInformationStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();

  return (
    <Stack>
      <div
        css={`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${rem(88)};

          ${lessThan(872)} {
            grid-template-columns: 1fr;
          }
        `}
      >
        <Fieldset>
          <Legend>Compléter les renseignements cliniques</Legend>

          <FieldsetContent>
            <div
              css={`
                display: grid;
                grid-gap: ${rem(28)};
                align-items: baseline;

                ${lessThan(theme.breakpoints.fonts[0])} {
                  grid-template-columns: 1fr;
                }
              `}
            >
              <TextAreaField
                label='Détails'
                name='details'
                value={values.details}
                onChange={handleChange}
                onBlur={handleBlur}
                rows='3'
              />

              <TextInputField
                label='Nom du référent*'
                name='referentName'
                value={values.referentName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.referentName && touched.referentName}
                helperText={touched.referentName ? errors.referentName : ''}
              />

              <InputMask
                mask='(999) 999-9999'
                beforeMaskedStateChange={beforeMaskedStateChange}
                type='phone'
                name='referentPhone'
                value={values.referentPhone}
                error={errors.referentPhone && touched.referentPhone}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={false}
              >
                <TextInputField
                  label='Téléphone du référent*'
                  helperText={touched.referentPhone ? errors.referentPhone : ''}
                />
              </InputMask>

              <TextInputField
                label='Courriel du référent*'
                name='referentEmail'
                value={values.referentEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.referentEmail && touched.referentEmail}
                helperText={touched.referentEmail ? errors.referentEmail : ''}
              />

              <SelectField
                label='Radiographies'
                name='radiography'
                value={values.radiography}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.radiography && touched.radiography}
                helperText={touched.radiography ? errors.radiography : ''}
              >
                {fillSelectPlaceholder('Sélectionnez une option').concat(
                  fillSelectOptions(radiographyOptions)
                )}
              </SelectField>
            </div>
          </FieldsetContent>
        </Fieldset>

        <Fieldset>
          <Legend>Joindre des documents (radiographies)</Legend>

          <FieldsetContent>
            <div
              css={`
                display: grid;
                grid-gap: ${rem(28)};
                grid-template-rows: minmax(144px, auto) repeat(5, 1fr);
                align-items: baseline;

                ${lessThan(theme.breakpoints.fonts[0])} {
                  grid-template-columns: 1fr;
                }
              `}
            >
              <div>
                <div
                  css={`
                    display: grid;
                    grid-template-columns: repeat(
                      auto-fill,
                      minmax(135px, 1fr)
                    );
                    grid-column-gap: ${rem(26)};
                    grid-row-gap: ${rem(52)};
                    margin-top: ${rem(21)};

                    ${lessThan(400)} {
                      justify-items: center;
                    }
                  `}
                >
                  {[...Array(4)].map((_, index) => (
                    <FileInput
                      // eslint-disable-next-line react/no-array-index-key
                      key={`document_${index}`}
                      name={`document_${index}`}
                      onChange={(val) => {
                        setFieldValue(`documents.${index}`, val);
                      }}
                    />
                  ))}
                </div>

                {errors.documents && (
                  <FormHelperText error>{errors.documents}</FormHelperText>
                )}
              </div>
              <InputMask
                mask='9999/99/99'
                beforeMaskedStateChange={beforeMaskedStateChange}
                name='dateRadiography'
                value={values.dateRadiography}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.dateRadiography && touched.dateRadiography}
                disabled={false}
                css={`
                  grid-row: 2;
                `}
              >
                <TextInputField
                  label='Date des radiographies AAAA/MM/JJ'
                  helperText={
                    touched.dateRadiography ? errors.dateRadiography : ''
                  }
                />
              </InputMask>

              <p
                css={`
                  grid-row: 3;
                  margin: 0;
                  font-size: ${rem(20)};
                `}
              >
                Vous recevrez une confirmation de réception après l’envoi du
                formulaire.
              </p>
            </div>
          </FieldsetContent>
        </Fieldset>
      </div>
    </Stack>
  );
};

export default ClinicalInformationStep;
