// vendors
import React from 'react';
import PropTypes from 'prop-types';
import MouthQuarter from '../MouthQuarter';
import { Container, Top, Bottom, Title } from './MouthSide.styles';

const MouthSide = ({
  numberTeethByQuarter,
  left,
  right,
  kid,
  onChange,
  onBlur,
  values,
  name,
}) => {
  const sideString = left && !right ? 'G' : 'D';

  return (
    <Container left={left} right={right}>
      <Title>{sideString}</Title>

      <Top>
        <MouthQuarter
          numberTeeth={numberTeethByQuarter}
          left={left}
          right={right}
          top
          kid={kid}
          onChange={onChange}
          onBlur={onBlur}
          values={values}
          name={name}
        />
      </Top>

      <Bottom>
        <MouthQuarter
          numberTeeth={numberTeethByQuarter}
          left={left}
          right={right}
          bottom
          kid={kid}
          onChange={onChange}
          onBlur={onBlur}
          values={values}
          name={name}
        />
      </Bottom>
    </Container>
  );
};

MouthSide.propTypes = {
  numberTeethByQuarter: PropTypes.number,
  left: PropTypes.bool,
  right: PropTypes.bool,
  kid: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
};

MouthSide.defaultProps = {
  numberTeethByQuarter: 8,
  left: false,
  right: false,
  kid: false,
  onChange: () => {},
  onBlur: () => {},
  values: [],
  name: '',
};

export default MouthSide;
