// Vendors
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import colors from '../styles/colors';

import RefinedLayout from '../components/RefinedLayout';
import Wizard from '../components/Wizard';

import IntroStep from '../views/RefererPatientForm/Steps/IntroStep';

import CliniqueSpecialistsStep, {
  title as titleCliniqueSpecialists,
  initialValues as initialValuesCliniquesSpecialists,
} from '../views/RefererPatientForm/Steps/CliniqueSpecialistsStep';

import PatientFileStep, {
  title as titlePatientFile,
  validationSchema as validationSchemaPatientFile,
  initialValues as initialValuesPatientFile,
} from '../views/RefererPatientForm/Steps/PatientFileStep';

import InterventionStep, {
  title as titleIntervention,
  initialValues as initialValuesIntervention,
  validationSchema as validationSchemaIntervention,
} from '../views/RefererPatientForm/Steps/InterventionStep';

import OdontogramStep, {
  title as titleOdontogram,
  initialValues as initialValuesOdontogram,
  validationSchema as validationSchemaOdontogram,
} from '../views/RefererPatientForm/Steps/OdontogramStep/OdontogramStep';

import ConfirmInformationStep, {
  title as titleConfirmInfo,
  initialValues as initialValuesConfirmInfo,
  validationSchema as validationSchemaConfirmInfo,
} from '../views/RefererPatientForm/Steps/ConfirmInformationStep/ConfirmInformationStep';

import ClinicalInformation, {
  title as titleClinicalInformation,
  initialValues as initialValuesClinicalInformation,
  validationSchema as validationSchemaClinicalInformation,
} from '../views/RefererPatientForm/Steps/ClinicalInformationStep';

import { useNotificationsCenter } from '../contexts/notificationsCenterContext';
import { bannerType } from '../components/NotificationsCenter/Banner';

const API_URL = process.env.GATSBY_API_URL || 'https://api.maxilloquebec.com';

const ReferePatientPage = ({ navigate }) => {
  const notificationIdRef = useRef(null);

  const { addNotification, removeNotification } = useNotificationsCenter();

  const handleSubmit = async (values, actions) => {
    if (notificationIdRef.current) {
      removeNotification(notificationIdRef.current);
    }

    try {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'documents') {
          values.documents.forEach((doc, index) => {
            formData.append(`document_${index + 1}`, doc);
          });

          return;
        }

        formData.append(key, values[key]);
      });

      const myInit = {
        method: 'POST',
        mode: 'cors',
        body: formData,
      };

      const response = await fetch(
        `${API_URL}/v1/form/refer-patient`,
        myInit
      ).then((res) => {
        if (res.status > 400) {
          throw new Error(res.statusText);
        }

        return res.json();
      });

      if (response.succes) {
        notificationIdRef.current = addNotification(
          'Votre formulaire a bien été envoyé. Nous prendrons contact avec vous prochainement.',
          {
            autoDismiss: true,
            type: bannerType.success,
          }
        );

        navigate('/');
      }

      if (response.inner && response.inner.length > 0) {
        response.inner.forEach(({ message, path }) =>
          actions.setFieldError(path, message)
        );

        const notificationMessage =
          response.inner.length > 1
            ? 'Il y a plusieurs champs incomplets.'
            : response.message;

        notificationIdRef.current = addNotification(notificationMessage, {
          autoDismiss: true,
          type: bannerType.error,
        });
      }

      actions.setSubmitting(false);
    } catch (error) {
      addNotification(
        'Une erreur s’est produite. Le formulaire n’a pu être envoyé. Veuillez essayer de nouveau.',
        {
          autoDismiss: true,
          type: bannerType.error,
        }
      );

      window.Sentry.captureException(error);

      console.error(error);
    }
  };

  return (
    <RefinedLayout backgroundColor={colors.mineShaft}>
      <Wizard formTitle='Référer un patient' onSubmit={handleSubmit}>
        <IntroStep />

        <CliniqueSpecialistsStep
          title={titleCliniqueSpecialists}
          initialValues={initialValuesCliniquesSpecialists}
        />

        <PatientFileStep
          title={titlePatientFile}
          initialValues={initialValuesPatientFile}
          validationSchema={validationSchemaPatientFile}
        />

        <InterventionStep
          title={titleIntervention}
          initialValues={initialValuesIntervention}
          validationSchema={validationSchemaIntervention}
        />

        <OdontogramStep
          title={titleOdontogram}
          initialValues={initialValuesOdontogram}
          validationSchema={validationSchemaOdontogram}
        />

        <ClinicalInformation
          title={titleClinicalInformation}
          initialValues={initialValuesClinicalInformation}
          validationSchema={validationSchemaClinicalInformation}
        />

        <ConfirmInformationStep
          title={titleConfirmInfo}
          initialValues={initialValuesConfirmInfo}
          validationSchema={validationSchemaConfirmInfo}
        />
      </Wizard>
    </RefinedLayout>
  );
};

ReferePatientPage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default ReferePatientPage;
